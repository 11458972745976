import React from "react";

import AppHandler from "./AppHandler";

// CSS
import "./App.css";
import "./assets/css/main.css";
import "./assets/css/DraggableAndDroppable.css";
import "./assets/css/Style3dModel.css";

const App = () => {
  return <AppHandler />;
};

export default App;
