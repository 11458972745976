import React, { useEffect, useState } from "react";

const _Option = ({ selectedOption, option, onOptionSelect }) => {

	const [selectedClassName, setSelectedClassName] = useState("")

	useEffect(() => {
		if (selectedOption) {
			if (selectedOption.id === option.id) {
				setSelectedClassName("selected")
			}else{
				setSelectedClassName("")
			}
		}
	}, [selectedOption])

	return(
		<div
			className={`event-option-wrapper ${ selectedClassName }`}
			onClick={() => onOptionSelect(option) }
		>
			<div className="event-option-title">
				{option.title}:
			</div>
			<div className="event-option-desc">
				{option.description}
			</div>
		</div>
	)
}

export default _Option