import React, { useState, useEffect } from "react";

// CSS
import "../assets/css/GroupCard.css";
import Translate from "../utils/Translate";

export default function GroupCard({ activeCustomerCardIds, customerCards }) {
  const [groupData, setGroupData] = useState(undefined);

  useEffect(() => {
    if (customerCards) {
      let groupData = customerCards.filter((card) =>
        activeCustomerCardIds.includes(card.id)
      );
      groupData.sort((cardA, cardB) => {
        if (cardA.sort > cardB.sort) {
          return 1;
        } else if (cardA.sort < cardB.sort) {
          return -1;
        } else {
          return 0;
        }
      });
      setGroupData(groupData);
    }
  }, [activeCustomerCardIds]);

  return (
    <div className="customer-cards">
      {groupData &&
        groupData.map((group, index) => (
          <div>
            <div style={{ width: "32%", fontSize: "20px" }}>
              <span>
                <Translate alias="Board" word="Customer Card" />
              </span>
              <span style={{ color: "#fdf854", marginLeft: "20px" }}>
                {index + 1}
              </span>
            </div>

            <div
              style={{
                border: "1px solid white",
                height: "100%",
                margin: "0 10px",
              }}
            />
            <div className="customerCardValueContainer">
              <div className="d-grid customer-card-value-row">
                <span>
                  <Translate alias="Board" word="Prerequisites" />:
                </span>
                <span style={{ flex: 1, textAlign: "end" }}>
                  {
                    (group.SimRemoteCustomerCardTranslation) ?
                      group.SimRemoteCustomerCardTranslation.prerequisites
                      :
                      group.prerequisites
                  }
                </span>
              </div>
              <div className="d-grid customer-card-value-row">
                <span style={{ flex: 1 }}>
                  <Translate alias="Board" word="Time Needed" />:
                </span>
                <span style={{ flex: 1, textAlign: "end" }}>
                  {group.time_needed}
                </span>
              </div>
              <div
                className="d-grid customer-card-value-row"
                style={{
                  color: "#fdf854",
                }}
              >
                <span style={{ flex: 1 }}>
                  <Translate alias="Board" word="Return" />:
                </span>
                <span style={{ textAlign: "end" }}>{group.return}</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
