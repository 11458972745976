const initState = {
    Score: {
        score: 0,
        reputation: 0,
        competence: 0,
        satisfaction: 0,
        return: 0
    },
	Teams: {
		TeamLeader: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		},
		Blue: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		},
		Green: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		},
		Red: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		},
		Yellow: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		},
		White: {
			awarenessOfRole: 0,
			belongingness: 0,
			competence: 0,
			satisfaction: 0
		}
	},
	Relations: {
		TeamLeaderBlue: 0,
		TeamLeaderRed: 0,
		TeamLeaderGreen: 0,
		TeamLeaderYellow: 0,
		TeamLeaderWhite: 0,
		BlueRed: 0,
		BlueGreen: 0,
		BlueYellow: 0,
		BlueWhite: 0,
		RedGreen: 0,
		RedYellow: 0,
		RedWhite: 0,
		GreenYellow: 0,
		GreenWhite: 0,
		YellowWhite: 0
	}
};

export default initState;
