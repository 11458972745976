import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createStore } from "redux";
import { Provider } from "react-redux";
import axios from "axios";
import SocketProvider from "./socket_context/index";

// Helpers
import URLs from "./utils/urls";
import reducer from "./redux/index";

// Components
import SimRemote from "./components/SimRemote";
import HomeComponent from "./components/HomeComponent";

import loaderImg from "./assets/images/loader.gif";

function findGetParameter(parameterName) {
  let result = null,
    tmp = [];
  let items = window.location.search.substr(1).split("&");
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

window.sessionId = parseInt(findGetParameter("session_id"));
window.playerId = parseInt(findGetParameter("user_id"));
window.adminId = parseInt(findGetParameter("admin_id"));
window.teamId = parseInt(findGetParameter("team_id"));
window.token = findGetParameter("token");

const store = createStore(reducer);
const AppHandler = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [partnerLogo, setPartnerLogo] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notAuthorizedMessage, setNotAuthorizedMessage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [customerCards, setCustomerCards] = useState([]);

  const fetchAuthenticate = async () => {
    const result = await axios.post(
      URLs.authenticate,
      {
        playerId: !isNaN(window.playerId) ? window.playerId : 0,
        adminId: !isNaN(window.adminId) ? window.adminId : 0,
        teamId: window.teamId,
        token: window.token,
        sessionId: window.sessionId,
        langId: localStorage.langId,
      },
      { headers: { "content-type": "application/json" } }
    );

    if (result.data.success) {
      let response = result.data;

      if (!isNaN(window.adminId)) {
        window.playerId = window.adminId;
        window.playerName =
          response.player.firstName + " " + response.player.lastName;
      } else {
        window.playerName = response.player.name;
      }
      window.staticWords = response.localizations.staticWords;
      console.log(response);
      setLanguages(response.languages);
      setIsAuthorized(true);
      setPartnerLogo(response.partner?.logo);
      setTeamName(
        response.teamData
          ? response.teamData.name
          : response.player.Teams[0].name
      );
      if (response.customerCardGroup) {
        setCustomerCards(response.customerCardGroup[0].CustomerCards);
      }
      setCanEdit(response.canEdit);
      setIsAdmin(!isNaN(window.adminId) ? true : false);
    } else {
      setNotAuthorizedMessage(result.data.message);
      setIsAuthorized(false);

      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      fetchAuthenticate();
    }
  }, [isLoading]);

  return (
    <Provider store={store}>
      <SocketProvider>
        {isAuthorized ? (
          <DndProvider backend={HTML5Backend}>
            <SimRemote
              canEdit={canEdit}
              teamNameValue={teamName}
              partnerLogo={partnerLogo}
              languages={languages}
              isAdmin={isAdmin}
              customerCards={customerCards}
            />
          </DndProvider>
        ) : (
          <div className="AuthorizationContainer">
            <div className="relativeContainer">
              {isLoading ? (
                <div className="NotAuthorizedContainerLoaderImg">
                  <div className="loaderContainer">
                    <img src={loaderImg} />
                  </div>
                </div>
              ) : (
                <HomeComponent
                  isLoggedOut={isLoggedOut}
                  notAuthorizedMessage={notAuthorizedMessage}
                />
              )}
            </div>
          </div>
        )}
      </SocketProvider>
    </Provider>
  );
};

export default AppHandler;
