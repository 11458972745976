import io from "socket.io-client"
import { socketEvents } from "./events"
import { getQueueLength } from "./emit"

import URLs from "../utils/urls";

export const socket = io(URLs.socketEndpoint, {
	upgrade: true,
	transports: ["websocket", "polling"], //['websocket', 'polling']
});

export const initSockets = ({ dispatch }) => {
	socketEvents({ dispatch });
	// dispatch    ^ is passed on to be used by socketEvents
	//getQueueLength();
};
