import React from "react";
import { socket } from "../socket/index";

// Step Back
const oneStepBack = () => {
  socket.emit("backward-step", {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
  });
};

// Add Container
const addContainer = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    character: options.name,
    type: options.type,
    coins: options.coins,
  };

  socket.emit("add-container", dataObj);
};

// Increase team table
const increaseTeamTable = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    coinType: options.coinType,
    value: options.value !== undefined ? options.value : 1,
  };

  socket.emit("increase-starter-kit", dataObj);
};

const updateTimeImpact = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    obj: options.obj,
  };

  socket.emit("time-coins-impact", dataObj);
};

const nextTimeImpact = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    name: options.name,
    eventId: options.eventId,
    impactIndex: options.impactIndex,
  };

  socket.emit("next-time-impact", dataObj);
};

const nextMoneyImpact = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    name: options.name,
    eventId: options.eventId,
    impactIndex: options.impactIndex,
  };

  socket.emit("next-money-impact", dataObj);
};

const updateMoneyImpact = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    obj: options.obj,
  };

  socket.emit("money-coins-impact", dataObj);
};

// Decrease team table
const decreaseTeamTable = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    coinType: options.coinType,
    value: options.value !== undefined ? options.value : 1,
  };

  socket.emit("decrease-starter-kit", dataObj);
};
// Remove Container
const removeContainer = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    type: options.type,
    character: options.name,
  };
  socket.emit("remove-container", dataObj);
};

// Add Coin
const addCoin = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    character: options.name,
    type: options.type,
    coins: 1, //options.coins
  };

  socket.emit("add-coin", dataObj);
};

// Remove Coin
const removeCoin = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    character: options.name,
    type: options.type,
    coins: 1, //options.coins
  };

  socket.emit("remove-coin", dataObj);
};

// Invested Add Container
const investedAddContainer = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    character: options.name,
    time: options.time,
    budget: options.budget,
  };

  socket.emit("add-container", dataObj);
};

// Invested Remove Container
const investedRemoveContainer = (options) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    alias: options.alias,
    character: options.name,
  };

  socket.emit("remove-container", dataObj);
};

// Select Option
const selectOption = (event, option) => {
  const dataObj = {
    teamId: window.teamId,
    sessionId: window.sessionId,
    playerId: window.playerId,
    playerName: window.playerName,
    eventData: event,
    optionData: option,
  };

  socket.emit("select-event-option", dataObj);
};

// Update Team Name
const changeTeamName = (options) => {
  socket.emit("change-team", {
    teamId: window.teamId,
    sessionId: window.sessionId,
    value: options.value,
  });
};
// View Last Active Event
const viewLastActiveEvent = (options) => {
  socket.emit("view-last-active-event", {
    teamId: window.teamId,
    sessionId: window.sessionId,
    lastActiveEventId: options.lastActiveEventId,
  });
};

// Update Team Name
const probabilityCard = (options) => {
  socket.emit("probability-card", {
    sessionId: window.sessionId,

    teamId: window.teamId,
    eventId: options.eventId,
    status: options.status,
    index: options.index,
  });
};

// Send Message
const sendMessage = (options) => {
  socket.emit("add-message", {
    sessionId: window.sessionId,
    teamId: window.teamId,
    playerId: window.playerId,
    playerName: window.playerName,
    message: options.message,
  });
};

const RealtimeConnection = {
  oneStepBack,
  addContainer,
  removeContainer,
  addCoin,
  removeCoin,
  investedAddContainer,
  investedRemoveContainer,
  selectOption,
  changeTeamName,
  sendMessage,
  probabilityCard,
  increaseTeamTable,
  decreaseTeamTable,
  viewLastActiveEvent,
  updateTimeImpact,
  updateMoneyImpact,
  nextTimeImpact,
  nextMoneyImpact,
};

export default RealtimeConnection;
