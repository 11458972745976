const TeamPosition = {
  Blue: {
    top: "323",
    right: "1015",
    color: "#005B96",
  },
  TeamLeader: {
    top: "597",
    right: "198",
    color: "#899196",
  },
  White: {
    top: "70",
    right: "380",
    color: "#899196",
  },
  Red: {
    top: "251",
    right: "46",
    color: "#D01349",
  },
  Green: {
    top: "380",
    right: "614",
    color: "#00a94f",
  },
  Yellow: {
    top: "70",
    right: "822",
    color: "#FCB813",
  },
};

export default TeamPosition;
