import React from "react";
import { Image } from "react-bootstrap";
import { DragPreviewImage, useDrag } from "react-dnd";

// Constants
import ITEMS from "../../utils/items";

// Images
import boxImg from "../../assets/images/draggable-container.png";
import previewImg from "../../assets/images/draggable-container-preview.png";

const MainDraggableContainer = ({ canEdit }) => {
  const [, drag, preview] = useDrag({
    type: ITEMS.container,
    item: {
      type: ITEMS.container,
      coins: 0,
      time: 0,
      budget: 0,
    },
  });

  const canDrag = canEdit ? drag : null;
  return (
    <>
      <DragPreviewImage
        className="dragging"
        connect={preview}
        src={previewImg}
      />
      <div className="containerDraggable" ref={canDrag}>
        <Image src={boxImg} />
      </div>
    </>
  );
};

export default MainDraggableContainer;
