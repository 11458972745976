import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

// Images
import SimdustryLogo from "../assets/images/simdustry-icon-logo.png";
import HappyImg from "../assets/images/HappyFace.png";
import SadImg from "../assets/images/SadFace.png";

// CSS
import "../assets/css/custom-probability-cards.css";

const _ProbabilityCardsPopup = () => {
  const sessionState = useSelector((state) => state.session);


  const [isOpen, setIsOpen] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (sessionState.customProbabilityCards) {
      setIsOpen(sessionState.customProbabilityCards.status);
      setSelectedCards(sessionState.customProbabilityCards.selectedCards);
      setCards(sessionState.customProbabilityCards.cards);
    }
  }, [sessionState]);

  return (
    <Modal isOpen={isOpen} size={"lg"} className="modal-dialog-centered">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{sessionState.customProbabilityCards.probabilityValue}%</h1>
            <div className="cards-grid">
              {cards.map((card, index) => {
                return (
                  <div key={index} className={`card-item`}>
                    {selectedCards.includes(index) ? (
                      <img
                        src={card === 1 ? HappyImg : SadImg}
                        style={{ width: 80 }}
                        alt=""
                      />
                    ) : (
                      <React.Fragment>
                        {selectedCards.length > 0 ? (
                          <img
                            src={card === 1 ? HappyImg : SadImg}
                            className="blurCard"
                          />
                        ) : (
                          <div className="card-content">
                            <img src={SimdustryLogo} style={{ width: "90%" }} />
                            <span className="card-number">{index + 1}</span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default _ProbabilityCardsPopup;
