import { socket } from "./index";

export const setupSocketData = () => {
	socket.emit("user-data", {
		user_id: window.playerId,
		team_id: window.teamId,
		session_id: window.sessionId,
		token: window.token,
	});
}



export const addClientToQueue = () => {
	socket.emit('addClientIdToQueue');
}

export const getQueueLength = () => {
	socket.emit('queueLengthToSocket');
}

export const removeUserFromQueue = () => {
	socket.emit('removeUserFromQueue');
}
