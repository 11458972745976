import React, { useState } from "react";

// Images
import documentIcon from "../../assets/images/document-icon.png";
import urls from "../../utils/urls";

export default function FixedRightBar({ files }) {
	const [isMenuOpened, setMenuOpened] = useState(false);

	function toggleFilesMenu() {
		if (isMenuOpened) {
			setMenuOpened(false);
			updateUI("FILES", "close");
		} else {
			setMenuOpened(true);
			updateUI("FILES", "open");
		}
	}

	function updateUI(panelType, status) {
		const appContainer =
			document.getElementsByClassName("center-container");
		const filesContainer =
			document.getElementsByClassName("filesContainer");

		if (status === "close") {
			appContainer[0].classList.remove("moveRight");
			filesContainer[0].classList.remove("fullWidth");
		} else {
			if (panelType === "FILES") {
				appContainer[0].classList.add("moveRight");
				window.scrollTo({
					left: 380,
					behavior: "smooth",
				});
				filesContainer[0].classList.add("fullWidth");
			} else {
				this.setState({ isMenuOpened: false }, () => {
					filesContainer[0].classList.remove("fullWidth");
				});
			}
		}
	}
	return (
		<>
			<div className="files-library-btn">
				<div className="section" onClick={toggleFilesMenu}>
					<div className="button">
						<img alt="documentIcon" src={documentIcon} />
						<div className="text">Files Library</div>
					</div>
				</div>
			</div>
			<div className="checkListContainer">
				<div className="filesContainer">
					<div className="files-header">
						<div className="files-header-logo">
							<img alt="documentIcon" src={documentIcon} />
							<div className="text">Files Library</div>
						</div>
					</div>
					{files && files.length > 0 && (
						<div className="files-section">
							<div className="filesListTitle">Files</div>
							{files.map((obj, index) => {
								return (
									<div className="fileRow" key={index}>
										<div className="file-link">
											<a
												href={`${urls.backendURL}/uploads/sessions/images/${obj.file}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{obj.name}
											</a>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
