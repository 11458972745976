import React, { useEffect, useState } from "react";

import { ReactComponent as TeamScore } from "../assets/SVG/teamScore.svg";

import "../assets/css/left-bar.css";
import InvestedDroppableField from "./Droppables/InvestedDroppableField";
import Translate from "../utils/Translate";

export default function LeftBar({
  canEdit,
  stateData,
  teamsData,
  teamsKeys,
  relationsData,
  activeCustomerCardIds,
  timeCoinsImpact,
  moneyCoinsImpact,
  scoreData,
}) {
  const [teamScore, setTeamScore] = useState(0);
  const [teamsInvestedKeys, setTeamsInvestedKeys] = useState([]);
  useEffect(() => {
    let sumCompetence = 0;
    let sumSatisfaction = 0;
    let sumBelongingness = 0;
    let sumAwarenessOfRole = 0;
    let sumTimeLeft = 0;
    let sumBudgetLeft = 0;
    let sumRelation = 0;
    teamsKeys.map((team) => {
      sumCompetence += teamsData[team].competence;
      sumSatisfaction += teamsData[team].satisfaction;
      sumBelongingness += teamsData[team].belongingness;
      sumAwarenessOfRole += teamsData[team].awarenessOfRole;
      sumTimeLeft += teamsData[team].time.coins;
      sumBudgetLeft += teamsData[team].budget.coins;
    });
    Object.keys(relationsData).map((x) => (sumRelation += relationsData[x]));

    const marketingScore = stateData.Marketing.budget * 3;
    const reputationScore = scoreData.reputation * 5;

    let teamScore = sumCompetence * 2;
    teamScore += sumSatisfaction * 2;
    teamScore += sumRelation * 2;
    teamScore += sumBelongingness * 5;
    teamScore += sumAwarenessOfRole * 5;
    teamScore += sumTimeLeft * 0.5;
    teamScore += sumBudgetLeft * 0.5;
    teamScore += marketingScore;
    teamScore += reputationScore;

    setTeamScore(teamScore);
  }, [teamsData, relationsData, stateData, scoreData]);

  useEffect(() => {
    if (stateData) {
      setTeamsInvestedKeys(Object.keys(stateData));
    }
  }, [stateData]);
  return (
    <div
      className="d-flex flex-column"
      style={{ position: "relative", marginLeft: "40px" }}
    >
      <div className="left-bar">
        <div className="teamscore">
          <span>
            <Translate alias="Board" word="TEAMSCORE" />
          </span>
          <div style={{ position: "relative" }}>
            <span className="team-score-value">{teamScore}</span>
            <TeamScore className="team-score-badge" />
          </div>
        </div>
      </div>

      <div className="invested">
        <div className="invested-title">
          <span>
            <Translate alias="Board" word="INVESTED" />
          </span>
        </div>

        <div className="d-flex">
          {teamsInvestedKeys.map((team, index) => (
            <div key={index} className="side-bar-block">
              <InvestedDroppableField
                canEdit={canEdit}
                alias={"Invested"}
                name={team}
                data={stateData[team]}
                timeCoinsImpact={timeCoinsImpact}
                moneyCoinsImpact={moneyCoinsImpact}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
