import React from "react";
import { useDrop } from "react-dnd";

// Helpers
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import { ReactComponent as Time } from "../../assets/SVG/time.svg";
import { ReactComponent as MoneySign } from "../../assets/SVG/moneySign.svg";
import container from "../../assets/images/container-h.png";
import containerV from "../../assets/images/container-v.png";
import { Button } from "react-bootstrap";

// Components
import DraggableContainer from "../Draggables/DraggableContainer";

const DroppableField = ({
  canEdit,
  alias,
  name,
  type,
  data,
  teamContainer,
  timeCoinsImpact,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ITEMS.container,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item, monitor) => {
      if (monitor.getHandlerId() === item.containerId) {
        return {};
      }
      if (item.name === "TeamLeader" && type === "budget") {
        return {};
      }
      if (
        name === "TeamLeader" &&
        item.type === "budget" &&
        item.name !== "General"
      ) {
        return {};
      }
      if (item.type === "Container") {
        RealtimeConnection.addContainer({
          alias: alias,
          name: name,
          type: type,
          coins: 0,
        });
      }
      if (
        item.name === "General" &&
        name === "TeamLeader" &&
        item.type === "budget"
      ) {
        RealtimeConnection.addContainer({
          alias: alias,
          name: name,
          type: type,
          coins: item.coins ? item.coins : item.budget,
        });
      } else if (name !== item.name) {
        return {};
      } else {
        RealtimeConnection.addContainer({
          alias: alias,
          name: name,
          type: type,
          coins: item.coins ? item.coins : item.time,
        });
      }

      return { success: true };
    },
  });

  const characterName = name.charAt(0).toLowerCase() + name.slice(1);
  let show = false;
  let impactObj = undefined;
  let impactEventId = undefined;
  let impactIndexValue = undefined;
  if (timeCoinsImpact) {
    const eventsKeys = Object.keys(timeCoinsImpact);
    if (eventsKeys.length > 0) {
      for (let eventIndex = 0; eventIndex < eventsKeys.length; eventIndex++) {
        const eventTimeImpacts = timeCoinsImpact[eventsKeys[eventIndex]];
        for (
          let impactIndex = 0;
          impactIndex < eventTimeImpacts.length;
          impactIndex++
        ) {
          const impact = eventTimeImpacts[impactIndex];
          if (!impact.status) {
            if (impact.impact[characterName]) {
              impactEventId = eventsKeys[eventIndex];
              impactIndexValue = impactIndex;
              impactObj = impact.impact;
              show = true;
            }
            break;
          }
        }
      }
    }
  }
  const handleNext = (e) => {
    e.stopPropagation();
    RealtimeConnection.nextTimeImpact({
      name: name.toLowerCase(),
      eventId: impactEventId,
      impactIndex: impactIndexValue,
    });
  };

  const playerCanDrop = canEdit && !data.hasContainer ? drop : null;
  const backgroundColor = isOver && canDrop && canEdit ? "#CCCCCC" : "";
  return (
    <React.Fragment>
      <div ref={playerCanDrop} style={{ backgroundColor: backgroundColor }}>
        {type === "time" && (
          <React.Fragment>
            {impactObj && show && (
              <div
                className="react-tiny-popover-container"
                style={{
                  top:
                    name === "Green" ? "10px" : name === "Yellow" ? "30px" : "",
                }}
              >
                <div>
                  <p>
                    Move {impactObj[characterName]}
                    {" Time Coins "}
                    {impactObj[characterName] > 0 ? " in " : " out of "}{" "}
                    {name + " "} Container to invested
                  </p>
                </div>

                {parseInt(data.coins) - parseInt(impactObj.currentTime) ===
                parseInt(impactObj[characterName]) ? (
                  <Button
                    className="nextBtnContainer"
                    size="sm"
                    onClick={(e) => handleNext(e)}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            )}

            {data.hasContainer ? (
              <DraggableContainer
                canEdit={canEdit}
                coins={data.coins}
                isHorizontal={!teamContainer}
                alias={alias}
                name={name}
                type={type}
              />
            ) : (
              <div className={`svgContainer ${name}`}>
                <Time />
              </div>
            )}
          </React.Fragment>
        )}
        {type === "budget" && (
          <React.Fragment>
            {data.hasContainer ? (
              <DraggableContainer
                canEdit={canEdit}
                coins={data.coins}
                isHorizontal={!teamContainer}
                alias={alias}
                name={name}
                type={type}
              />
            ) : (
              <div className={`svgContainer ${name}`}>
                <MoneySign />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default DroppableField;
