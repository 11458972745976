import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { initSockets } from "../socket/index";

const SocketProvider = (props) => {    
	const dispatch = useDispatch();

	useEffect(() => {
		initSockets({ dispatch })
	}, [initSockets]);
	
	return props.children
};
export default SocketProvider;
