import React from "react";
import { useDrop } from "react-dnd";

// Images
import deleteImg from "../../assets/images/delete.png";
import deleteActive from "../../assets/images/delete-active.png";

// Utils
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableDelete({ canEdit }) {
	const [{ isOver }, drop] = useDrop({
		accept: [ITEMS.container, ITEMS.coin],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			if (item.type === ITEMS.container) {
				RealtimeConnection.removeContainer({
					alias: item.alias,
					name: item.name,
					type: item.type,
				});

				return { success: true };
			} else if (item.type === ITEMS.coin) {
				RealtimeConnection.removeCoin({
					alias: item.alias,
					name: item.name,
					type: item.coinType,
				});

				return { success: true };
			}
		},
	});
	const canDrop = canEdit ? drop : null;
	return (
		<div className="removeDroppable" ref={canDrop}>
			<img alt="drop" src={isOver ? deleteActive : deleteImg} />
		</div>
	);
}
