import initState from "../states/init-team-table-state";

const reducer = (state = initState, action) => {
	switch (action.type) {
		case "RESTORE_TEAM_TABLE":
			return {
				...action.data,
			};
		case "INCREASE_TEAM_TABLE":
			let increasedCoins = state[action.data.coinType];
			increasedCoins += action.data.value;
			return {
				...state,
				[action.data.coinType]: increasedCoins,
			};

		case "DECREASE_TEAM_TABLE":
			let decreasedCoin = state[action.data.coinType];
			decreasedCoin -= action.data.value;
			return {
				...state,
				[action.data.coinType]: decreasedCoin,
			};
		case "UPDATE_TEAM_TABLE":
			return {
				...state,
				[action.data.coinType]: action.data.value,
			};

		default:
			break;
	}
	return state;
};

export default reducer;
