import React from "react";
import { useDrag, DragPreviewImage } from "react-dnd";

// Constants
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";

let numberOfUpdates = 0;

export default function DraggableCoin({
  canEdit,
  containerId,
  value,
  alias,
  name,
  type,
  isLastCoin,
}) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: type === "time" ? items.time : items.coin,
    item: {
      type: type === "time" ? items.time : items.coin,
      name: name,
      coinType: type,
      value: value,
      containerId: containerId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const doppableItem = monitor.getDropResult();
      if (monitor.didDrop()) {
        if (doppableItem && doppableItem.success) {
          RealtimeConnection.removeCoin({
            alias: alias,
            name: name,
            type: type,
            coins: dropResult.value,
          });
        }
      }
    },
  });

  let previewImg = previewGoldCoinImg;
  let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
  switch (value) {
    case 1:
      coinClass += "gold";
      previewImg = previewGoldCoinImg;
      break;
    case 10:
      coinClass += "silver";
      previewImg = previewSilverCoinImg;
      break;
    default:
      break;
  }

  let canDrag = canEdit ? drag : null;

  let dragClass = "";

  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div
        ref={canDrag}
        className={["coin", coinClass, dragClass].join(" ")}
      ></div>
    </>
  );
}
