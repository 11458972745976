import initState from "../states/session-init-state";

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "RESTORE_STATE":
      return {
        ...state,
        score: action.data.score,
        teamName: action.data.teamName,
        selectedEvents: action.data.selectedEvents,
        probabilityCard: action.data.probabilityCard,
        timeCoinsImpact: action.data.timeCoins,
        moneyCoinsImpact: action.data.moneyCoins,
      };

    case "UPDATE_TEAM_SCORE":
      return {
        ...state,
        score: {
          ...state.score,
          [action.data.type]: action.data.value,
        },
      };
    case "UPDATE_ROUND":
      let roundNumber = state.roundNumber;
      roundNumber += 1;

      return {
        ...state,
        roundNumber: roundNumber,
      };

    case "RESTORE_ROUND":
      return {
        ...state,
        roundNumber: action.round,
      };
    case "RESTORE_SESSION_DATA":
      return {
        ...state,
        team: action.data.team,
        teamName: action.data.teamName,
        teamLead: action.data.teamLead,
        organization: action.data.organization,
        time: action.data.time,
        amount: action.data.amount,
      };
    case "UPDATE_SESSION":
      switch (action.data.type.toUpperCase()) {
        case "TEAM":
          return {
            ...state,
            team: action.data.value ? parseInt(action.data.value) : 0,
          };
        case "TEAMLEAD":
          return {
            ...state,
            teamLead: action.data.value ? parseInt(action.data.value) : 0,
          };
        case "ORGANIZATION":
          return {
            ...state,
            organization: action.data.value ? parseInt(action.data.value) : 0,
          };
        case "SCORE":
          return {
            ...state,
            score: action.data.value ? parseInt(action.data.value) : 0,
          };
        case "TIME":
          return {
            ...state,
            time: action.data.value ? parseInt(action.data.value) : 0,
          };
        /*case 'MONEY':
					return {
						...state,
						amount: (action.data.value) ? parseInt(action.data.value) : 0,
					};*/
        case "AMOUNT":
          return {
            ...state,
            amount: action.data.value ? parseInt(action.data.value) : 0,
          };
        case "SPENT":
          return {
            ...state,
            spent: {
              ...state.spent,
              time: action.data.time,
              money: action.data.money,
            },
          };
        default:
          break;
      }
      break;
    case "UPDATE_FILES":
      return {
        ...state,
        files: action.data,
      };
    case "ADD_FILE": {

      let listOfFiles = state.files;
      listOfFiles.push(action.data);

      return {
        ...state,
        files: listOfFiles,
      };
    }
    case "REMOVE_FILE": {
      let listOfFiles = state.files.filter(
        (fileObj) => fileObj._id !== action.data._id
      );

      return {
        ...state,
        files: listOfFiles,
      };
    }
    case "CHANGE_TEAM_NAME":
      return {
        ...state,
        teamName: action.data.value,
      };
    case "OPEN_EVENT":
      if (state.event && state.currentEvent.id === state.lastEventId) {
        return {
          ...state,
          development: false,
          event: !state.event,
          currentEvent: {},
          eventsCurrentView: "",
          selectedEventOption: {},
          eventOptionCards: [],
          selectedEventCard: undefined,
        };
      } else {
        return {
          ...state,
          development: false,
          event: !state.event, //true
        };
      }
    case "UPDATE_CURRENT_EVENT": {
      return {
        ...state,
        currentEvent: action.event,
      };
    }

    case "UPDATE_SELECT_EVENT_OPTION":
      return {
        ...state,
        selectedEvents: {
          ...state.selectedEvents,
          [action.data.eventData.id]: action.data.optionData,
        },
      };
    case "PROBABILITY_CARD":
      return {
        ...state,
        probabilityCard: {
          ...state.probabilityCard,
          [action.data.eventId]: {
            status: action.data.status,
            index: action.data.index,
          },
        },
      };
    case "REMOVE_SELECT_EVENT_OPTION":
      return {
        ...state,
        selectedEvents: {
          ...state.selectedEvents,
          [action.data.eventId]: undefined,
        },
      };
    case "SELECT_CARD":
      return {
        ...state,
        selectedEventCard: action.card,
      };
    case "UPDATE_EVENT_VIEW":
      return {
        ...state,
        eventsCurrentView: action.view,
      };
    case "UPDATE_EVENT_CARDS":
      return {
        ...state,
        eventOptionCards: action.cards,
      };
    case "RESTORE_LOCALIZATION":
      return {
        ...state,
        localizations: action.localizations,
      };
    case "UPDATE_SESSION_EVENT_ID":
      return {
        ...state,
        currentEventId: action.data.eventId ? action.data.eventId : undefined,
      };
    case "UPDATE_SESSION_LAST_EVENT_ID":
      return {
        ...state,
        lastActiveEventId: action.data.eventId,
      };
    case "TIME_COINS_IMPACT":
      return {
        ...state,
        timeCoinsImpact: {
          ...state.timeCoinsImpact,
          [action.data.eventId]: action.data.impactArray,
        },
      };
    case "MONEY_COINS_IMPACT":
      return {
        ...state,
        moneyCoinsImpact: {
          ...state.moneyCoinsImpact,
          [action.data.eventId]: action.data.impactArray,
        },
      };
    case "TIME_COINS_NEXT":
      return {
        ...state,
        timeCoinsImpact: {
          ...state.timeCoinsImpact,
          [action.data.eventId]: state.timeCoinsImpact[action.data.eventId].map(
            (impact, index) => {
              if (index === action.data.impactIndex) {
                impact.status = true;
              }
              return impact;
            }
          ),
        },
      };

    /*let obj = state.timeCoinsImpact;
			if (action.data.name === "teamleader") {
				delete obj["teamLeader"];
			} else {
				delete obj[action.data.name];
			}

			return {
				...state,
				timeCoinsImpact: obj,
			};*/
    case "MONEY_COINS_NEXT":
      return {
        ...state,
        moneyCoinsImpact: {
          ...state.moneyCoinsImpact,
          [action.data.eventId]: state.moneyCoinsImpact[
            action.data.eventId
          ].map((impact, index) => {
            if (index === action.data.impactIndex) {
              impact.status = true;
            }
            return impact;
          }),
        },
      };
    /*let moneyObj = state.moneyCoinsImpact;
			if (action.data.name === "teamleader") {
				delete moneyObj["teamLeader"];
			} else {
				delete moneyObj[action.data.name];
			}

			return {
				...state,
				moneyCoinsImpact: moneyObj,
			};*/
    case "VIEW_LAST_ACTIVE_EVENT":
      return {
        ...state,
        viewLastActiveEvent: true,
        eventCardAppearanceStatus: {
          allowOptionSelection: false,
          showTimeCoins: true,
          showMoneyCoins: true,
          showProbability: true,
          showImpact: true,
        },
      };
    case "ClOSE_LAST_ACTIVE_EVENT":
      return {
        ...state,
        viewLastActiveEvent: false,
        eventCardAppearanceStatus: {
          allowOptionSelection: false,
          showTimeCoins: false,
          showMoneyCoins: false,
          showProbability: false,
          showImpact: false,
        },
      };
    case "UPDATE_SESSION_CARDS":
      return {
        ...state,
        activeCustomerCardIds: action.data.customerCardIds,
      };
    case "UPDATE_SESSION_GROUP_ID":
      let listOfIds = [];

      if (
        state.activeCustomerCardIds &&
        state.activeCustomerCardIds.includes(action.data.groupId)
      ) {
        listOfIds = state.activeCustomerCardIds.filter(
          (id) => id !== action.data.groupId
        );
      } else {
        if (state.activeCustomerCardIds) {
          listOfIds = state.activeCustomerCardIds;
          listOfIds.push(action.data.groupId);
        } else {
          listOfIds.push(action.data.groupId);
        }
      }

      return {
        ...state,
        activeCustomerCardIds: listOfIds,
      };
    case "RESTORE_EVENT_CARD_STATUS":
      return {
        ...state,
        eventSectionStatus: action.data.eventSectionStatus,
      };
    case "UPDATE_EVENT_CARD_STATUS":
      return {
        ...state,
        eventSectionStatus: {
          ...state.eventSectionStatus,
          [action.data.eventId]: {
            ...state.eventSectionStatus[action.data.eventId],
            [action.data.type]: action.data.status,
          },
        },
      };
    case "UPDATE_EVENT_SLIDE_STATUS":
      return {
        ...state,
        eventSlideAppearance: {
          enabled: action.data.status,
          slide: action.data.slide,
        },
      };
    case "RESTORE_CHARACTER_PROFILES":
      return {
        ...state,
        charactersProfile: action.data,
      };
    case "RESTORE_MESSAGES_LIST":
      return {
        ...state,
        messages: action.data,
      };
    case "ADD_MESSAGE":
      let messagesList = state.messages;
      messagesList.push(action.data);

      return {
        ...state,
        messages: messagesList,
      };
    case "APPLY_DEFAULT_VALUES":
      return {
        ...state,
        score: {
          ...state.score,
          reputation:
            action.data.teamScore && action.data.teamScore.reputation
              ? parseInt(action.data.teamScore.reputation)
              : 0,
          //return: action.data.teamScore ? parseInt(action.data.teamScore.return) : 0
        },
      };
    case "UPDATE_PROBABILITY_CARDS_VALUE":
      return {
        ...state,
        customProbabilityCards: {
          ...state.customProbabilityCards,
          probabilityValue: action.data.value,
          cards: action.data.cards,
          selectedCards: [],
        },
      };
    case "RESTORE_PROBABILITY_CARDS_REVEAL":
      return {
        ...state,
        customProbabilityCards: {
          ...state.customProbabilityCards,
          selectedCards: action.data.cards,
        },
      };
    case "UPDATE_PROBABILITY_CARDS_STATUS":
      return {
        ...state,
        customProbabilityCards: {
          ...state.customProbabilityCards,
          status: action.data.status,
        },
      };
    case "PROBABILITY_CARDS_REVEAL":
      return {
        ...state,
        customProbabilityCards: {
          ...state.customProbabilityCards,
          selectedCards: [
            ...state.customProbabilityCards.selectedCards,
            action.data.index,
          ],
        },
      };
    case "APPLY_IMPACT_STATUS":
      return {
        ...state,
        applyImpact: action.eventId,
      };
    case "COUNTER":
      return {
        ...state,
        counter: action.data,
      };
    case "EVENT_PAGE_TRANSLATION":
      return {
        ...state,
        fetchEventPageTranslate: action.data,
      };
    case "CENTRAL_BOARD_DATA":
      return {
        ...state,
        centralBoardData: action.data,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
