import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  top: ${({ top }) => `${top}px`};
  right: ${({ right }) => `${right}px`};
  ::before {
    transform: ${({ translateB4, angle }) =>
      translateB4
        ? `rotate(${angle}deg) translate(${translateB4}px)`
        : `rotate(${angle}deg)`};
    background: ${({ beforeBG }) =>
      beforeBG === "#BFC5C9" ? "white" : beforeBG};
    height: ${({ beforeH8 }) => `${beforeH8}px`};
  }
  ::after {
    transform: ${({ angle, translate }) =>
      `rotate(${angle}deg) translate(${translate}px)`};
    background: ${({ afterBG }) => (afterBG === "#BFC5C9" ? "white" : afterBG)};
    height: ${({ afterH8 }) => `${afterH8}px`};
  }
`;
const BeforeSvgStyle = styled.svg`
  width: 9px;
  svg > g > g > * {
    fill: ${({ beforeBG }) => `${beforeBG}`};
  }
`;
const AfterSvgStyle = styled.svg`
  width: 9px;
  svg > g > g > * {
    fill: ${({ afterBG }) => `${afterBG}`};
  }
`;

export function StyledDivComp({ children, ...restProps }) {
  return <StyledDiv {...restProps}>{children}</StyledDiv>;
}

export function BeforeSvgStyleComp({ children, ...restProps }) {
  return <BeforeSvgStyle {...restProps}>{children}</BeforeSvgStyle>;
}

export function AfterSvgStyleComp({ children, ...restProps }) {
  return <AfterSvgStyle {...restProps}>{children}</AfterSvgStyle>;
}
