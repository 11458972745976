import React from "react";

const _Slide = ({ title, body }) => {

	return (
		<div className="slide-wrapper">
			<div className="slide-header-content">
				<div dangerouslySetInnerHTML={{ __html: title }}></div>
			</div>
			<div className="slide-body-content">
				<div dangerouslySetInnerHTML={{ __html: body }}></div>
			</div>
		</div>
	)
}

export default _Slide