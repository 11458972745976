import React from "react";
import styled from "styled-components";
import { ReactComponent as Person } from "../../assets/SVG/person.svg";
import { ReactComponent as Arrow } from "../../assets/SVG/left-arrow.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";
import {
  AfterSvgStyleComp,
  BeforeSvgStyleComp,
  StyledDivComp,
} from "./StyledComponents";

export default function RelationCard({
  angle,
  translate,
  top,
  right,
  beforeBG,
  afterBG,
  beforeH8,
  afterH8,
  translateB4,
  value,
  impactValue,
}) {
  return (
    <StyledDivComp
      angle={angle}
      translate={translate}
      top={top}
      right={right}
      afterBG={afterBG}
      beforeBG={beforeBG}
      afterH8={afterH8}
      translateB4={translateB4 * 0.5}
      beforeH8={beforeH8}
      className="relationship"
    >
      <div className="relationship-card">
        {impactValue !== 0 ? (
          <div
            className={`impact-visual-dots ${
              impactValue > 0 ? "green" : "red"
            }`}
          >
            {impactValue > 0 ? "+" : ""}
            {impactValue}
          </div>
        ) : null}
        <img
          src={value >= 0 ? HappyFace : SadFace}
          alt=""
          className="happy-face"
        />
        <Person className="person" />
        <AfterSvgStyleComp
          angle={angle}
          translate={translate}
          top={top}
          right={right}
          afterBG={afterBG}
          beforeBG={beforeBG}
          afterH8={afterH8}
          translateB4={translateB4}
          beforeH8={beforeH8}
          className="left-arrow"
        >
          <Arrow />
        </AfterSvgStyleComp>
        <BeforeSvgStyleComp
          angle={angle}
          translate={translate}
          top={top}
          right={right}
          afterBG={afterBG}
          beforeBG={beforeBG}
          afterH8={afterH8}
          translateB4={translateB4}
          beforeH8={beforeH8}
          className="right-arrow"
        >
          <Arrow />
        </BeforeSvgStyleComp>
        <Person className="person" />
        <span>Relationship</span>
      </div>
      <span className="relation-card-value">{value}</span>
    </StyledDivComp>
  );
}
