import React, { useEffect, useState } from "react";

// Images
import { ReactComponent as CompetenceSVG } from "../../assets/SVG/competence.svg";
import { ReactComponent as Satisfaction } from "../../assets/SVG/satisfaction.svg";
import { ReactComponent as Avatar } from "../../assets/SVG/avatar_arrows.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";

// Components
import DroppableField from "../Droppables/DroppableField";
import Profile from "./Profile";
import Translate from "../../utils/Translate";

// CSS
import "../../assets/css/single-circle.css";

export default function SingleCircle({
  canEdit,
  name,
  stateData,
  impactData,
  color,
  top,
  right,
  timeCoinsImpact,
}) {
  const [isWhite, setIsWhite] = useState(false);
  const [isGrey, setIsGrey] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const Styles = () => {
    let styles = {
      backgroundColor: `${color}`,
      top: `${top}px`,
      right: `${right}px`,
    };
    if (isWhite) {
      styles.backgroundColor = "white";
    }
    return styles;
  };

  useEffect(() => {
    if (name === "TeamLeader") {
      setIsGrey(true);
    }
    if (name === "White") {
      setIsWhite(true);
    }
  }, [name]);
  const handleProfile = (e) => {
    e.preventDefault();
    setOpenProfile(true);
  };
  return (
    <div>
      <div
        className="circle"
        style={Styles()}
        onClick={(e) => handleProfile(e)}
      >
        <div style={{ position: "relative" }}>
          <div className={`avatar ${name}`}>
            <Avatar />
          </div>
          <div
            className="rectangle"
            style={isWhite ? { border: "1px solid #8A9298" } : null}
          >
            <div className="flex-column">
              <Translate alias="Board" word="Awareness of role / function" />
              {/* <span>function</span> */}
              {impactData.awarenessOfRole !== 0 ? (
                <span
                  className={`impact-visual-dots ${
                    impactData.awarenessOfRole > 0 ? "green" : "red"
                  }`}
                >
                  {impactData.awarenessOfRole > 0 ? "+" : ""}
                  {impactData.awarenessOfRole}
                </span>
              ) : null}
            </div>
            <div className="rings">
              {[...Array(5)].map((x, y) => (
                <div
                  className={`ring ${
                    stateData.awarenessOfRole > y && "checked"
                  }`}
                  style={{ marginTop: y * -3.5, right: y * -13 }}
                />
              ))}
            </div>
          </div>

          <div
            className="rectangle"
            style={isWhite ? { border: "1px solid #8A9298" } : null}
          >
            <div>
              <Translate alias="Board" word="Belongingness" />
              {impactData.belongingness !== 0 ? (
                <span
                  className={`ml-2 impact-visual-dots ${
                    impactData.belongingness > 0 ? "green" : "red"
                  }`}
                >
                  {impactData.belongingness > 0 ? "+" : ""}
                  {impactData.belongingness}
                </span>
              ) : null}
            </div>
            <div className="rings">
              {[...Array(5)].map((x, y) => (
                <div
                  className={`ring ${stateData.belongingness > y && "checked"}`}
                  style={{ marginTop: y * -3.5, right: y * -13 }}
                />
              ))}
            </div>
          </div>
          <div className="three-bars">
            <div className={isWhite && "white"}>
              <div style={isWhite ? { border: `1px solid ${color}` } : null}>
                <span style={{ fontSize: "24px", color: color }}>
                  {impactData.competence !== 0 ? (
                    <span
                      className={
                        impactData.competence > 0
                          ? "impact-increase"
                          : "impact-decrease"
                      }
                    >
                      {impactData.competence > 0 ? "+" : ""}
                      {impactData.competence}
                    </span>
                  ) : null}
                  {stateData.competence}
                </span>
                <div className={`svgContainer ${name}`}>
                  <CompetenceSVG />
                </div>
              </div>
              <img
                src={stateData.competence >= 0 ? HappyFace : SadFace}
                alt=""
                className="happy-face"
              />
              <span>
                <Translate alias="Board" word="Competence" />
              </span>
            </div>
            <div className={isWhite && "white"}>
              <div style={isWhite ? { border: `1px solid ${color}` } : null}>
                <span style={{ fontSize: "24px", color: color }}>
                  {impactData.satisfaction !== 0 ? (
                    <span
                      className={
                        impactData.satisfaction > 0
                          ? "impact-increase"
                          : "impact-decrease"
                      }
                    >
                      {impactData.satisfaction > 0 ? "+" : ""}
                      {impactData.satisfaction}
                    </span>
                  ) : null}
                  {stateData.satisfaction}
                </span>
                <div className={`svgContainer ${name}`}>
                  <Satisfaction />
                </div>
              </div>
              <img
                src={stateData.satisfaction >= 0 ? HappyFace : SadFace}
                alt=""
                className="happy-face"
              />
              <span>
                <Translate alias="Board" word="Satisfaction" />
              </span>{" "}
            </div>
            <div style={isWhite ? { color: color } : null}>
              <div style={isWhite ? { border: `1px solid ${color}` } : null}>
                {" "}
                <DroppableField
                  teamContainer={true}
                  canEdit={canEdit}
                  alias={"Teams"}
                  name={name}
                  type={"time"}
                  data={stateData.time}
                  timeCoinsImpact={timeCoinsImpact}
                />
                <span>
                  <Translate alias="Board" word="Time" />
                </span>{" "}
              </div>
            </div>
            {isGrey && (
              <div>
                <div style={{ border: `1px solid ${color}` }}>
                  <DroppableField
                    teamContainer={true}
                    canEdit={canEdit}
                    alias={"Teams"}
                    name={name}
                    type={"budget"}
                    data={stateData.budget}
                    timeCoinsImpact={timeCoinsImpact}
                  />
                </div>
                <span>
                  <Translate alias="Board" word="Budget" />
                </span>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      {openProfile && (
        <Profile name={name} closeProfile={() => setOpenProfile(false)} />
      )}
    </div>
  );
}
