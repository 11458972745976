import { socket } from './index';

export const socketEvents = ({ dispatch }) => {

	socket.on("connect", () => {
		console.log("Connected To WS");

		if (window.playerId) {
			socket.emit("user-data", {
				user_id: window.playerId,
				team_id: window.teamId,
				session_id: window.sessionId,
				token: window.token,
			});
		}
	})

	socket.on("disconnect", () => {
	});

	socket.on("restore-session", (data) => {
		dispatch({ type: "RESTORE_STATE", data });
		dispatch({ type: "RESTORE_TEAM_TABLE", data: data.starterKitStateObj });
	});

	socket.on("decrease-starter-kit", (data) => {
		dispatch({ type: "DECREASE_TEAM_TABLE", data: data });
	});

	socket.on("update-team-table", (data) => {
		dispatch({ type: "UPDATE_TEAM_TABLE", data });
	});

	/****************   Probability Cards   ****************/
	socket.on("change-probabibility-cards-value", data => {
		dispatch({ type: "UPDATE_PROBABILITY_CARDS_VALUE", data });
	})

	socket.on("change-probabibility-cards-status", data => {
		dispatch({ type: "UPDATE_PROBABILITY_CARDS_STATUS", data });
	})

	socket.on("probabibility-cards-reveal", data => {
		dispatch({ type: "PROBABILITY_CARDS_REVEAL", data });
	})

	socket.on("restore-probabibility-cards-selected-cards", data => {
		dispatch({ type: "RESTORE_PROBABILITY_CARDS_REVEAL", data });
	})
	/*******************************************************/

	socket.on("increase-starter-kit", (data) => {
		dispatch({ type: "INCREASE_TEAM_TABLE", data });
	});
	
	socket.on("update-character", (data) => {
		dispatch({ type: "UPDATE_CHARACTER", data });
	});
	
	socket.on("update-character-extra", data => {
		dispatch({ type: "UPDATE_CHARACTER_EXTRA", data });
	})

	socket.on("update-relation", (data) => {
		dispatch({ type: "UPDATE_RELATION", data });
	});

	socket.on("add-container", (data) => {
		dispatch({ type: "UPDATE_STATE", actionType: "AddContainer", data });
	});

	socket.on("remove-container", (data) => {
		dispatch({ type: "UPDATE_STATE", actionType: "RemoveContainer", data });
	});

	socket.on("add-coin", (data) => {
		dispatch({ type: "UPDATE_STATE", actionType: "AddCoin", data });
	});

	socket.on("remove-coin", (data) => {
		dispatch({ type: "UPDATE_STATE", actionType: "RemoveCoin", data });
	});

	socket.on("session-active-event", (data) => {
		dispatch({ type: "UPDATE_SESSION_EVENT_ID", data });
		if (data.eventId) {
			dispatch({ type: "UPDATE_SESSION_LAST_EVENT_ID", data });
		}
	});
	
	socket.on("session-last-active-event", (data) => {
		dispatch({ type: "UPDATE_SESSION_LAST_EVENT_ID", data });
	});

	socket.on("session-active-group", (data) => {
		dispatch({ type: "UPDATE_SESSION_GROUP_ID", data });
	});
	
	socket.on("session-active-cards", (data) => {
		dispatch({ type: "UPDATE_SESSION_CARDS", data });
	});

	socket.on("restore-event-section-status", data => {
		dispatch({ type: "RESTORE_EVENT_CARD_STATUS", data });
	})
	
	socket.on("event-card-appearance-status", (data) => {
		dispatch({ type: "UPDATE_EVENT_CARD_STATUS", data });
	});

	socket.on("select-event-option", (data) => {
		dispatch({ type: "UPDATE_SELECT_EVENT_OPTION", data });
	});
	
	socket.on("probability-card", (data) => {
		dispatch({ type: "PROBABILITY_CARD", data });
	});

	socket.on("update-invested", (data) => {
		dispatch({ type: "UPDATE_INVESTED", data });
	});

	socket.on("remove-event-option", (data) => {
		dispatch({ type: "REMOVE_SELECT_EVENT_OPTION", data });
	});

	socket.on("event-slide-status", (data) => {
		dispatch({ type: "EVENT_PAGE_TRANSLATION", data })
	});

	socket.on("update-team-score", (data) => {
		dispatch({ type: "UPDATE_TEAM_SCORE", data });

		/*socket.emit("my-center-board-data", {
			sessionId: data.sessionId
		});*/
	});

	
	socket.on("add-timer", (data) => {
		dispatch({ type: "COUNTER", data: data.data })
	});

	socket.on("remove-timer", (data) => {
		dispatch({ type: "COUNTER", data: undefined })
	});

	socket.on("change-team", (data) => {
		dispatch({ type: "CHANGE_TEAM_NAME", data });
	});

	socket.on("messages-list", (data) => {
		dispatch({ type: "RESTORE_MESSAGES_LIST", data });
	});

	socket.on("add-message", (data) => {
		dispatch({ type: "ADD_MESSAGE", data });
	});

	socket.on("session-status-changed", (data) => {
		window.location.reload();
	});

	socket.on("files-list", (data) => {
		dispatch({ type: "UPDATE_FILES", data });
	});
	
	socket.on("view-last-active-event", (data) => {
		dispatch({ type: "VIEW_LAST_ACTIVE_EVENT", data });
	});
	
	socket.on("add-file", (data) => {
		if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
			dispatch({ type: "ADD_FILE", data });
		}
	});

	socket.on("remove-file", (data) => {
		if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
			dispatch({ type: "REMOVE_FILE", data });
		}
	});

	socket.on("apply-default-values", (data) => {
		dispatch({ type: "APPLY_DEFAULT_VALUES", data });
	});
	
	socket.on("apply-additional-values", data => {
		dispatch({ type: "APPLY_ADDITIONAL_VALUES", data });
	})

	socket.on("time-coins-impact", (data) => {
		dispatch({ type: "TIME_COINS_IMPACT", data });
	});

	socket.on("money-coins-impact", (data) => {
		dispatch({ type: "MONEY_COINS_IMPACT", data });
	});

	socket.on("next-time-impact", (data) => {
		dispatch({ type: "TIME_COINS_NEXT", data });
	});

	socket.on("next-money-impact", (data) => {
		dispatch({ type: "MONEY_COINS_NEXT", data });
	});

	socket.on("money-coins-impact", (data) => {
		dispatch({ type: "MONEY_COINS_IMPACT", data });
	});

	socket.on("event-impact", data => {
		dispatch({ type: "IMPACT_RESTORE" })
		dispatch({ type: "APPLY_IMPACT_STATUS", eventId: data.eventId })

		//setApplyImpactOnEvent(data.eventId)
	})


	socket.on("center-board-data", (data) => {
		dispatch({ type: "CENTRAL_BOARD_DATA", data })
	});
};