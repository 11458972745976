import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import timePreview from "../../assets/images/time-preview.png";
import moneyPreview from "../../assets/images/dollar_coin_silver-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableCoin({ canEdit, value }) {
  const [, drag, preview] = useDrag({
    type: value === 1 ? items.time : items.coin,
    item: {
      coinType: value === 1 ? items.time : items.coin,
      value: value,
      isFromTeamTable: true,
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        let droppedItem = monitor.getDropResult();
        if (droppedItem !== null && droppedItem.success) {
          const obj = {
            coinType: value === 1 ? "timeCoins" : "moneyCoins",
            value: 1,
          };
          RealtimeConnection.decreaseTeamTable(obj);
        }
      }
    },
  });

  const canDrag = canEdit ? drag : null;
  let previewImg = previewGoldCoinImg;
  let img = previewGoldCoinImg;

  switch (value) {
    case 1:
      previewImg = previewGoldCoinImg;
      img = timePreview;
      break;
    case 10:
      previewImg = moneyPreview;
      img = moneyPreview;
      break;
    default:
      break;
  }
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div className="draggable-coins-container" ref={canDrag}>
        <img alt="draggable-coins" className="draggable-coins" src={img} />
      </div>
    </>
  );
}
