const urls = {
  backendURL: process.env.REACT_APP_BACKEND_URL,
  socketEndpoint: process.env.REACT_APP_SOCKET_URL,
  authenticate: process.env.REACT_APP_BACKEND_URL + "/api/player/login",
  logout: process.env.REACT_APP_BACKEND_URL + "/api/authenticate",
  renameTeam: process.env.REACT_APP_BACKEND_URL + "/api/team/rename",
  activeCounter: process.env.REACT_APP_BACKEND_URL + "/api/counter/active",
  eventLink: process.env.REACT_APP_BACKEND_URL + "/api/simremote/active/event",
  fetchEvent:
    process.env.REACT_APP_BACKEND_URL + "/simremote/common-event/active",
  groupLink:
    process.env.REACT_APP_BACKEND_URL + "/api/active/simremote-customer-card-group",
  characterProfiles:
    process.env.REACT_APP_BACKEND_URL + "/api/simremote/character/profiles",
  eventPageTranslate:
    process.env.REACT_APP_BACKEND_URL + "/api/simremote/event-page-translate",
};

export default urls;
