import React, { useEffect, useState } from "react";

// Imgs
import { ReactComponent as Arrow } from "../../assets/SVG/arrow.svg";

const _ResultImpactRow = ({ impact }) => {

	const [value, setValue] = useState(0)

	useEffect(() => {
		setValue(impact.value)
	}, [impact.value])

	return (
		<div className="result-impact-row">
			<span style={{ textTransform: "capitalize", width: 250 }}>
				{ impact.impactType }{" "}{ impact.impactOn }
			</span>
			<span className={`${ value > 0 ? "positive" : "negative" }`}>
				<Arrow className={`arrow ${ value > 0 ? "up" : "" }`} />
				{ `${ value > 0 ? "+" : "" } ${ value }` }
			</span>
		</div>
	)
}

export default _ResultImpactRow