import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { setupSocketData } from "../socket/emit";

// Components
import FixedHeader from "./FixedBars/FixedHeader";
import FixedLeftBar from "./FixedBars/FixedLeftBar";

import LeftBar from "./leftBar";
import RelationCard from "./Blocks/RelationCard";
import SingleCircle from "./Blocks/SingleCircle";
import EventCard from "./EventCard";
import CustomerCardGroup from "./CustomerCardGroup";
import RightBar from "./RightBar";

import _ProbabilityCardsPopup from "./_ProbabilityCardsPopup";

// Constants
import URLs from "../utils/urls";
import TeamPosition from "../utils/TeamPosition";
import RelationPosition from "../utils/RelationPosition";

// CSS
import "../assets/css/FixedBars.css";
import "../assets/css/impactVisual.css";

// Images
import BG from "../assets/images/bg.png";
import FixedRightBar from "./FixedBars/FixedRightBar";

import { socket } from "../socket/index";

let scrollIntervalTime = undefined;
const SimRemote = ({
  teamNameValue,
  canEdit,
  partnerLogo,
  languages,
  customerCards,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const boardState = useSelector((state) => state.board);
  const sessionState = useSelector((state) => state.session);
  const teamTableState = useSelector((state) => state.teamTable);
  const impactState = useSelector((state) => state.impact);

  const [zoomValue, setZoom] = useState(100);
  const [teamsKeys, setTeamsKeys] = useState([]);
  const [relationsKeys, setRelationsKeys] = useState([]);
  const [counter, setCounter] = useState(undefined);
  const [teamName, setTeamName] = useState("");

  const [applyImpactOnEvent, setApplyImpactOnEvent] = useState(undefined);

  useEffect(() => {
    setupSocketData();
    fetchProfiles();
  }, []);

  useEffect(() => {
    setTeamName(teamNameValue);
  }, [teamNameValue]);

  useEffect(() => {
    if (sessionState.fetchEventPageTranslate) {
      fetchEventPageTranslate(sessionState.fetchEventPageTranslate);
    }
  }, [sessionState.fetchEventPageTranslate]);

  useEffect(() => {
    if (sessionState.teamName) {
      setTeamName(sessionState.teamName);
    }
  }, [sessionState.teamName]);

  useEffect(() => {
    if (sessionState.counter) {
      setCounter(sessionState.counter);
    } else {
      setCounter(undefined);
    }
  }, [sessionState.counter]);

  useEffect(() => {
    if (sessionState.applyImpact) {
      applyEventImpact(sessionState.applyImpact);
    }
  }, [sessionState.applyImpact]);

  const applyEventImpact = (eventId) => {
    if (sessionState && sessionState.selectedEvents) {
      if (sessionState.selectedEvents[eventId]) {
        const selectedOption = sessionState.selectedEvents[eventId];
        const probabilityCards = sessionState.probabilityCard;

        let impactArray = [];
        if (selectedOption.hasProbability) {
          if (probabilityCards && probabilityCards[eventId]) {
            const selectedCard = probabilityCards[eventId];
            if (selectedCard.status === "happy") {
              impactArray = selectedOption.SuccessSystemImpact;
            } else {
              impactArray = selectedOption.FailureSystemImpact;
            }
          }
        } else {
          impactArray = [
            ...selectedOption.SuccessSystemImpact,
            ...selectedOption.FailureSystemImpact,
          ];
        }

        if (impactArray.length > 0) {
          for (let j = 0; j < impactArray.length; j++) {
            const impact = impactArray[j];
            if (impact.impactOn === "teamScore") {
              if (impact.impactType === "reputation") {
                if (impact.value < 0) {
                  impact.value = 1;
                }
              }

              dispatch({
                type: "UPDATE_TEAM_SCORE",
                data: {
                  type: impact.impactType,
                  value: impact.value ? impact.value : 0,
                },
              });
              // Update Result Visuals
              dispatch({
                type: "IMPACT_UPDATE_SCORE",
                data: {
                  type: impact.impactType,
                  value: impact.value ? impact.value : 0,
                },
              });
            } else if (impact.impactOn === "relation") {
              const relationKey = impact.impactType.replace("-", "");
              dispatch({
                type: "INCREASE_RELATION",
                data: {
                  key: relationKey,
                  value: impact.value ? impact.value : 0,
                },
              });
              // Update Result Visuals
              dispatch({
                type: "IMPACT_UPDATE_RELATION",
                data: {
                  key: relationKey,
                  value: impact.value ? impact.value : 0,
                },
              });
            } else {
              const character =
                impact.impactOn.charAt(0).toUpperCase() +
                impact.impactOn.slice(1);
              dispatch({
                type: "INCREASE_CHARACTER",
                data: {
                  character: character,
                  type:
                    impact.impactType === "awareness"
                      ? "awarenessOfRole"
                      : impact.impactType,
                  value: impact.value ? impact.value : 0,
                },
              });
              // Update Result Visuals
              dispatch({
                type: "IMPACT_UPDATE_CHARACTER",
                data: {
                  character: character,
                  key:
                    impact.impactType === "awareness"
                      ? "awarenessOfRole"
                      : impact.impactType,
                  value: impact.value ? impact.value : 0,
                },
              });
            }
          }
        }
      }
    }

    dispatch({ type: "APPLY_IMPACT_STATUS", eventId: undefined });
  };

  useEffect(() => {
    if (applyImpactOnEvent) {
      applyEventImpact(applyImpactOnEvent);
      setApplyImpactOnEvent(undefined);
    }
  }, [applyImpactOnEvent]);

  useEffect(() => {
    if (boardState.Teams) {
      setTeamsKeys(Object.keys(boardState.Teams));
    }
  }, [boardState.Teams]);

  useEffect(() => {
    setRelationsKeys(Object.keys(boardState.Relations));
  }, [boardState.Relations]);

  const fetchProfiles = async () => {
    const result = await axios.post(
      URLs.characterProfiles,
      { languageId: localStorage.langId, sessionId: window.sessionId },
      {
        headers: { "content-type": "application/json" },
      }
    );

    if (result.data.success) {
      let profiles = {};
      result.data.profiles.forEach((profile) => {
        if (!profiles[profile.character.toLowerCase()]) {
          profiles[profile.character.toLowerCase()] = [];
        }

        profiles[profile.character.toLowerCase()].push(profile);
      });

      dispatch({ type: "RESTORE_CHARACTER_PROFILES", data: profiles });
    }
  };

  const fetchEventPageTranslate = async (data) => {
    if (data.slide) {
      const result = await axios.post(
        URLs.eventPageTranslate,
        { id: data.slide.id, languageId: localStorage.langId, },
        {
          headers: { "content-type": "application/json" },
        }
      );

      dispatch({
        type: "UPDATE_EVENT_SLIDE_STATUS",
        data: {
          status: data.status,
          slide: result.data.page ? result.data.page : data.slide,
        },
      });
    }
  };

  const updateDrageMousePosition = (ev) => {
    if (ev.clientX >= window.innerWidth - 100) {
      if (!scrollIntervalTime) {
        scrollIntervalTime = setInterval(() => {
          scrollRightAction();
        }, 50);
      }
    } else if (ev.clientX <= 100) {
      if (!scrollIntervalTime) {
        scrollIntervalTime = setInterval(() => {
          scrollLeftAction();
        }, 50);
      }
    } else {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("dragover", updateDrageMousePosition);
    return () =>
      window.removeEventListener("dragover", updateDrageMousePosition);
  }, []);

  const scrollRightAction = () => {
    window.scrollBy({
      left: 50,
      behavior: "smooth",
    });

    if (
      document.body.scrollWidth - window.innerWidth ===
      window.pageXOffset - (window.innerWidth - document.body.offsetWidth)
    ) {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  const scrollLeftAction = () => {
    window.scrollBy({
      left: -50,
      behavior: "smooth",
    });

    if (window.pageXOffset === 0) {
      if (scrollIntervalTime) {
        clearInterval(scrollIntervalTime);
        scrollIntervalTime = undefined;
      }
    }
  };

  return (
    <div className="simTeam-board">
      <img src={BG} alt="" className="bg" />
      <FixedHeader
        dispatch={dispatch}
        setZoom={setZoom}
        zoomValue={zoomValue}
        teamName={teamName}
        counter={counter}
        partnerLogo={partnerLogo}
        languages={languages}
        lastActiveEventId={sessionState.lastActiveEventId}
        isAdmin={isAdmin}
      />
      <FixedLeftBar canEdit={canEdit} state={teamTableState} />
      <FixedRightBar files={sessionState.files} />
      <div>
        {sessionState.activeCustomerCardIds &&
          sessionState.activeCustomerCardIds.length > 0 && (
            <CustomerCardGroup
              activeCustomerCardIds={sessionState.activeCustomerCardIds}
              customerCards={customerCards}
            />
          )}
      </div>
      <div
        className={`center-container d-flex zoom-${zoomValue} ${
          sessionState.activeCustomerCardIds &&
          sessionState.activeCustomerCardIds.length > 0
            ? "more-padding"
            : ""
        }`}
        style={{ position: "relative" }}
      >
        <LeftBar
          canEdit={canEdit}
          stateData={boardState.Invested}
          teamsData={boardState.Teams}
          relationsData={boardState.Relations}
          scoreData={sessionState.score}
          teamsKeys={teamsKeys}
          activeCustomerCardIds={
            sessionState.activeCustomerCardIds !== undefined
          }
          timeCoinsImpact={sessionState.timeCoinsImpact}
          moneyCoinsImpact={sessionState.moneyCoinsImpact}
        />
        <RightBar
          name={sessionState.teamName}
          scoreData={sessionState.score}
          teamsData={boardState.Teams}
          teamsKeys={teamsKeys}
          socket={socket}
          activeCustomerCardIds={
            sessionState.activeCustomerCardIds !== undefined
          }
        />
        <div className="wrapper">
          <div className="containers">
            {teamsKeys.map((team) => (
              <SingleCircle
                key={team.id}
                canEdit={canEdit}
                name={team}
                stateData={boardState.Teams[team]}
                impactData={impactState.Teams[team]}
                top={TeamPosition[team].top}
                right={TeamPosition[team].right}
                color={TeamPosition[team].color}
                timeCoinsImpact={sessionState.timeCoinsImpact}
              />
            ))}
            {/* Connection Lines */}
            {relationsKeys.map((relation) => (
              <RelationCard
                key={relation}
                name={relation}
                value={boardState.Relations[relation]}
                impactValue={impactState.Relations[relation]}
                angle={RelationPosition[relation].angle}
                afterBG={RelationPosition[relation].afterBG}
                beforeBG={RelationPosition[relation].beforeBG}
                beforeH8={RelationPosition[relation].beforeH8}
                translate={RelationPosition[relation].translate}
                translateB4={RelationPosition[relation].translateB4}
                afterH8={RelationPosition[relation].afterH8}
                top={RelationPosition[relation].top}
                right={RelationPosition[relation].right}
              />
            ))}
          </div>
        </div>
      </div>

      <EventCard
        eventId={
          sessionState.currentEventId ? sessionState.currentEventId : null
        }
        lastActiveEventId={
          sessionState.lastActiveEventId ? sessionState.lastActiveEventId : null
        }
        viewLastActiveEvent={sessionState.viewLastActiveEvent}
        dispatch={dispatch}
      />
      <_ProbabilityCardsPopup />
    </div>
  );
};

export default SimRemote;
