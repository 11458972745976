const RelationPosition = {
  TeamLeaderBlue: {
    angle: "288",
    beforeBG: "#005B96",
    afterBG: "#899196",
    translate: "25",
    afterH8: "400",
    beforeH8: "400",
    top: "558",
    right: "718",
  },
  TeamLeaderRed: {
    angle: "216",
    beforeBG: "#899196",
    afterBG: "#D01349",
    translate: "17",
    translateB4: "5",
    afterH8: "200",
    top: "490",
    right: "122",
  },
  TeamLeaderGreen: {
    angle: "296",
    afterBG: "#899196",
    beforeBG: "#00a94f",
    translate: "16",
    translateB4: "-6",
    top: "567",
    right: "490",
  },
  TeamLeaderYellow: {
    angle: "126",
    afterBG: "#FCB813",
    beforeBG: "#899196",
    translate: "-22",
    translateB4: "-1",
    afterH8: "490",
    top: "477",
    right: "421",
  },
  TeamLeaderWhite: {
    angle: "162",
    afterBG: "#BFC5C9",
    beforeBG: "#899196",
    afterH8: "400",
    translate: "2",
    translateB4: "9",
    top: "508",
    right: "270",
  },
  BlueRed: {
    angle: "268",
    afterBG: "#D01349",
    beforeBG: "#005B96",
    translate: "29",
    translateB4: "4",
    beforeH8: "600",
    top: "327",
    right: "420",
  },
  BlueGreen: {
    angle: "280",
    beforeBG: "#005B96",
    afterBG: "#00a94f",
    translate: "23",
    top: "426",
    right: "864",
  },
  BlueYellow: {
    angle: "222",
    afterBG: "#FCB813",
    beforeBG: "#005B96",
    translate: "22",
    translateB4: "9",
    beforeH8: "120",
    afterH8: "190",
    top: "226",
    right: "1056",
  },
  BlueWhite: {
    angle: "247",
    afterBG: "#BFC5C9",
    beforeBG: "#005B96",
    translate: "23",
    afterH8: "430",
    top: "296",
    right: "897",
  },
  RedGreen: {
    angle: "256",
    afterBG: "#D01349",
    beforeBG: "#00a94f",
    translate: "33",
    translateB4: "7",
    afterH8: "100",
    beforeH8: "400",
    top: "372",
    right: "251",
  },
  RedYellow: {
    angle: "281",
    afterBG: "#D01349",
    beforeBG: "#FCB813",
    translate: "20",
    translateB4: "-5",
    beforeH8: "300",
    afterH8: "460",
    top: "226",
    right: "587",
  },
  RedWhite: {
    angle: "298",
    afterBG: "#D01349",
    beforeBG: "#BFC5C9",
    translate: "23",
    translateB4: "2",
    beforeH8: "120",
    afterH8: "140",
    top: "171",
    right: "243",
  },
  GreenYellow: {
    angle: "141",
    afterBG: "#FCB813",
    beforeBG: "#00a94f",
    translate: "-17",
    top: "298",
    right: "764",
  },
  GreenWhite: {
    angle: "221",
    afterBG: "#BFC5C9",
    beforeBG: "#00a94f",
    translate: "15",
    translateB4: "-6",
    top: "287",
    afterH8: "130",
    right: "545",
  },
  YellowWhite: {
    angle: "91",
    afterBG: "#FCB813",
    beforeBG: "#BFC5C9",
    translate: "-40",
    translateB4: "-15",
    afterH8: "200",
    top: "112",
    right: "663",
  },
};

export default RelationPosition;
