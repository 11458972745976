import { combineReducers } from "redux";

import boardReducer from "./reducers/board-reducer";
import sessionReducer from "./reducers/session-reducer";
import teamTableReducer from "./reducers/team-table-reducer";
import impactReducer from "./reducers/impact-reducer";

export default combineReducers({
  board: boardReducer,
  session: sessionReducer,
  teamTable: teamTableReducer,
  impact: impactReducer,
});
